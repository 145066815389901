/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import C from '../constants'
import SEO from '../components/Seo'
import PageLayout from '../components/PageLayout'
import BannerWrapper from '../components/BannerWrapper'
import BackButton from '../components/BackButton'
import TextBlock from '../components/TextBlock'
import Banner from '../components/Banner'
import FreeTrialBanner from '../components/FreeTrialBanner'
import PriceBlock from '../components/PriceBlock'
import IconText from '../components/IconText'

const Pricing = () => {
  const { t } = useTranslation()
  return (
    <PageLayout>
      <SEO title="Pricing" />
      <BannerWrapper>
        <BackButton />
        <Banner
          imageName="desk-ipad-plate"
          imageAlt={t('ImageAlts:imageAlt5')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('PricingPage:firstBannerHeading')}
            textBeforeLink={t('PricingPage:firstBannerTextBeforeLink')}
            linkText={t('Global:signUp')}
            href={C.links.signupLink}
            textAfterLink={t('PricingPage:firstBannerTextAfterLink')}
          />
        </Banner>
        <Flex
          sx={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginLeft: [0, 0, 0, 5],
          }}
        >
          <PriceBlock
            heading={t('PricingPage:priceHeader1')}
            detail={t('PricingPage:priceDetail1')}
            buttonText={t('Global:getStarted')}
          >
            <IconText text={t('PricingPage:listItem1')} />
            <IconText text={t('PricingPage:listItem2')} />
            <IconText text={t('PricingPage:listItem3')} />
            <IconText text={t('PricingPage:listItem4')} />
          </PriceBlock>
          <PriceBlock
            heading={t('PricingPage:priceHeader2')}
            detail={t('PricingPage:priceDetail2')}
            buttonText={t('Global:getStarted')}
          >
            <IconText text={t('PricingPage:listItem5')} />
            <IconText text={t('PricingPage:listItem2')} />
            <IconText text={t('PricingPage:listItem6')} />
            <IconText text={t('PricingPage:listItem7')} />
          </PriceBlock>
          <PriceBlock
            heading={t('PricingPage:priceHeader3')}
            detail={t('PricingPage:priceDetail3')}
            buttonText={t('Global:getStarted')}
          >
            <IconText text={t('PricingPage:listItem5')} />
            <IconText text={t('PricingPage:listItem2')} />
            <IconText text={t('PricingPage:listItem6')} />
            <IconText text={t('PricingPage:listItem7')} />
          </PriceBlock>
        </Flex>
      </BannerWrapper>
      <FreeTrialBanner />
    </PageLayout>
  )
}

export default Pricing

/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Box, Button, Text, jsx } from 'theme-ui'

const PriceBlock = ({ heading, detail, buttonText, children }) => (
  <Flex
    p={3}
    sx={{
      flexDirection: 'column',
      minWidth: ['250px', '250px', '250px', '350px'],
    }}
  >
    <Text variant="priceHeading">{heading}</Text>
    <Text variant="priceDetail">{detail}</Text>
    <Button
      variant="primary"
      px={4}
      py={2}
      sx={{ marginTop: 4, marginBottom: 4, width: '180px' }}
      onClick={() => {}}
    >
      {buttonText}
    </Button>
    <Box sx={{ flexDirection: 'colum' }}>{children}</Box>
  </Flex>
)

export default PriceBlock
